<template>
  <div class="text-body">
    <LandingPage/>
  </div>
</template>

<script>
import LandingPage from './pages/LandingPage.vue';

require('./styles/index.scss');

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
html,
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
