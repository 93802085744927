<template>
  <div class="page-container mx-auto d-flex justify-content-start flex-sm-column">
    <div class="text-center mt-4 mb-10">
      <img src="../assets/logo.png" />
      <img src="../assets/woodmark.png" />
    </div>
    <div>
      <h1 class="choose-option-heading text-center mb-4">Was möchten Sie als Nächstes machen?</h1>
      <!-- <p class="instruction-paragraph text-placeholder mb-4">
        If you do not have Jameda Desktop yet click on the button to install it first
      </p> -->
    </div>
    <button class="btn btn-primary mb-1" @click="openDeepLinkUrl">
      jameda Desktop öffnen
    </button>
    <button class="btn btn-light mb-4" @click="downloadJamedaDesktop">
      jameda Desktop herunterladen
    </button>
    <button class="btn text-primary" @click="openWebVersion">Web Version öffnen</button>
  </div>
</template>

<script>
import mixpanel from 'mixpanel-browser';

const MIXPANEL_KEY = '8a2fada95f8e5bcdfecdba553132a598';

const TrackEvent = {
  LOAD_LANDING_PAGE: "EMAIL_LP load_landing_page",
  OPEN_DESKTOP_APP: "EMAIL_LP click_open_dektop_app",
  DOWNLOAD_DESKTOP_APP: "EMAIL_LP click_download_app",
  OPEN_WEB_APP: "EMAIL_LP click_open_web",
}

mixpanel.init(MIXPANEL_KEY, {debug: true}); 

export default {
  name: "LandingPage",
  data() {
    return {
      redirectUrl: null,
      osVersion: null,
    };
  },
  mounted() {
    mixpanel.track(TrackEvent.LOAD_LANDING_PAGE);
    const href = window.location.href;
    const searchParams = new URLSearchParams(href.substring(href.indexOf("?")));
    this.redirectUrl = searchParams.get("redirectUrl");
  },
  methods: {
    openDeepLinkUrl() {
      mixpanel.track(TrackEvent.OPEN_DESKTOP_APP);
      let linkToOpen = 'docplanner://docplanner.jameda.de/';

      if (this.redirectUrl) {
        linkToOpen = this.redirectUrl;
      }

      window.location = linkToOpen;
    },
    downloadJamedaDesktop() {
      mixpanel.track(TrackEvent.DOWNLOAD_DESKTOP_APP);
      const downloadLink = {
        mac: "https://pms-connect.jameda.de/download/darwin/main",
        win: "https://pms-connect.jameda.de/download/win32/main",
      };
      const osVersion = this.getOsVersion();
      window.location = downloadLink[osVersion];
    },
    getOsVersion() {
      const userAgent = navigator.userAgent.toLowerCase();
      let os = null;
      if (userAgent.indexOf("windows") >= 1) {
        os = "win";
      } else if (userAgent.indexOf("mac") > -1) {
        os = "mac";
      }
      return os;
    },
    openWebVersion() {
      mixpanel.track(TrackEvent.OPEN_WEB_APP);
      
      let linkToOpen = 'https://docplanner.jameda.de/';

      if (this.redirectUrl) {
        const urlStringWithDocplannerProtocolRemoved = this.redirectUrl.split(`docplanner://`)[1];
        linkToOpen = `https://${urlStringWithDocplannerProtocolRemoved}`;
      }
      
      window.open(linkToOpen);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-container {
  width: 600px;
}

.choose-option-heading {
  font-size: 26px;
  line-height: 30px;
}

.instruction-paragraph {
  line-height: 22px;
  font-size: 15px;
}
</style>
